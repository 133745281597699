import {
    createHttpRequest
} from '@jutro/transport';
import appConfig from 'app-config';
import { logError } from '../../../../applications/account-management/src/components/AppInsightsComponent/AppInsightsComponent';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

let restService;
const getRestService = (authHeader) => {
    if (!restService) {
        const authOptions = {
            'Authorization': authHeader.Authorization,
            'Ocp-Apim-Subscription-Key': appConfig.env.CRM_OCP_SUBSCRIPTION_KEY
        };
        const baseUrl = appConfig.env.CRM_BASE_URL;

        restService = createHttpRequest(baseUrl, false);
        restService = restService.build();
        restService.defaultOptions.headers = authOptions;
    }
    return restService;
};

export default class CustomCRMService {
    static enrollUser(auth, url, data) {
        let contactProfile;
        try {
            contactProfile = getRestService(auth).post(url, JSON.stringify(data));
        } catch (error) {
            logError(err, SeverityLevel.Information, 'CustomCRMService.enrollUser', 'APIException in CustomCRMService.enrollUser for' +`${url}`);
            if (error) {
                return undefined;
            }
        }
        return contactProfile;
    }

    static getContactProfile(auth, url, data) {
        let contactProfile;
        try {
            contactProfile = getRestService(auth).get(url, data);
        } catch (error) {
            logError(err, SeverityLevel.Information, 'CustomCRMService.getContactProfile', 'APIException in CustomCRMService.getContactProfile for '+ `${url}`);
            if (error) {
                return undefined;
            }
        }
        return contactProfile;
    }

    static updateAccountPreferences(auth, url, data) {
        let userResp;
        try {
            userResp = getRestService(auth).post(url, JSON.stringify(data));
        } catch (error) {
            logError(err, SeverityLevel.Information, 'CustomCRMService.updateAccountPreferences', 'APIException in CustomCRMService.updateAccountPreferences for '+`${url}`); 
            if (error) {
                return undefined;
            }
        }
        return userResp;
    }

    static updateAccount(auth, url, data) {
        let contactProfile;
        try {
            contactProfile = getRestService(auth).post(url, JSON.stringify(data));
        } catch (error) {
            logError(err, SeverityLevel.Information, 'CustomCRMService.updateAccount', 'APIException in CustomCRMService.updateAccount for '+`${url}`); 
            if (error) {
                return undefined;
            }
        }
        return contactProfile;
    }

    static verifyPolicy(auth, url, data) {
        const verifyPolicy = getRestService(auth).get(url, data);
        return verifyPolicy;
    }

    static verifyUserPolicy(auth, url, data) {
        const verifyPolicyForUser = getRestService(auth).get(url, data);
        return verifyPolicyForUser;
    }

    static addEnrollment(auth, url, addEnrollmentData) {
        try {
            const enrollmentDetails = getRestService(auth).post(url, addEnrollmentData);
            return enrollmentDetails.enrolled;
        } catch (error) {
            logError(err, SeverityLevel.Information, 'CustomCRMService.addEnrollment', 'APIException in CustomCRMService.addEnrollment for '+`${url}`); 
            if (error.response) {
                const {
                    status,
                    data
                } = error.response;
                return undefined;
            }
        }
        return undefined;
    }
}
