import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import appConfig from 'app-config';
/**
 *  1582415 - Added for appInsights react plugin
 */
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: appConfig.env.APPINSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    disableFetchTracking:true,
    autoTrackPageVisitTime: true,
    //enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  }
});
appInsights.loadAppInsights();

function logError(error, severity, errorComponent, customMessage) {
  let errorMessage = error.message == null || error.message == undefined ? JSON.stringify(error) : error.message;
  const errorStack = error.stack == null || error.stack == undefined ? error : error.stack;
  errorMessage = customMessage == null || customMessage == undefined ? errorMessage : customMessage + errorMessage             
  reactPlugin.trackException(
    { exception: {
        message: errorMessage
      }, 
      severityLevel: severity}, 
    {'am-message': errorMessage,
    'am-error': errorStack,
    'am-component': errorComponent});
}

export { reactPlugin, appInsights, logError};